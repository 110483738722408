import { useOnboardingTranslation } from '@libs/utils/translations'
import * as yup from 'yup'

export const useForgottenPasswordFormValidation = () => {
  const { t } = useOnboardingTranslation()

  return yup.object().shape({
    email: yup
      .string()
      .required(t('onboarding.common.forms.validation.required'))
      .email(t('onboarding.common.forms.validation.invalidEmailFormat'))
  })
}
