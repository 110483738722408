import { useUncontrolled } from '@libs/ui/ds'
import { useInternalDesignSystem } from '@libs/ui/ds/lib/theme/DesignSystemProvider/InternalDesignSystemProvider'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'

import { Autocomplete } from '../../base/Autocomplete'

import type { CountryAutocompleteProps } from './CountryAutocomplete.types'
import { CountryAutocompleteTarget } from './CountryAutocompleteTarget'
import { getDerivedCountriesData } from './utils/utils'

const countryAutocompleteCVA = {
  flagImage: cva(['w-4'])
}

export const CountryAutocomplete: FC<CountryAutocompleteProps> = ({
  value,
  onChange,
  phoneCountryCode = false,
  condensedTarget = false,
  ...otherProps
}) => {
  const { config } = useInternalDesignSystem()
  const countriesDerivedData = getDerivedCountriesData(
    config.language,
    phoneCountryCode
  )
  const [selectedCountry, setSelectedCountry] = useUncontrolled<string>({
    value,
    onChange,
    defaultValue: config.language
  })

  return (
    <Autocomplete
      {...otherProps}
      isMulti={false}
      value={selectedCountry}
      onChange={setSelectedCountry}
      options={countriesDerivedData.map(i => ({
        value: i.code,
        label: i.label,
        'data-testid': `country-autocomplete-option-${i.code}`,
        leftSection: (
          <img
            loading='lazy'
            className={countryAutocompleteCVA.flagImage()}
            src={i.flagUrl}
            alt={i.name}
            data-testid={`country-autocomplete-flag-${i.code}`}
          />
        )
      }))}
      customTarget={props => (
        <CountryAutocompleteTarget
          {...props}
          condensedTarget={condensedTarget}
        />
      )}
    />
  )
}
