import { Text, Title } from '@libs/ui/ds'
import { useOnboardingTranslation } from '@libs/utils/translations'

import { useConfigurationAuthNavbar } from '../shared/services/authNavbar/AuthNavbarContext'

import { ForgottenPasswordForm } from './ForgottenPasswordForm'

const ForgottenPassword = () => {
  const { t } = useOnboardingTranslation()

  useConfigurationAuthNavbar({
    languages: true
  })

  return (
    <>
      <Title className='text-xxl'>
        {t('onboarding.password.forgotten.title')}
      </Title>
      <Text className='mb-10 mt-2 text-s'>
        {t('onboarding.password.forgotten.description')}
      </Text>

      <ForgottenPasswordForm />
    </>
  )
}

export default ForgottenPassword
