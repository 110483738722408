import { useCombobox } from '@mantine/core'
import { useMergedRef } from '@mantine/hooks'
import { forwardRef, memo, useRef } from 'react'

import { Combobox } from '../../../../overlays/Combobox/base/Combobox'
import { SelectEmpty } from '../../base/subs/SelectEmpty'
import { SelectOptionList } from '../../base/subs/SelectOptionList'

import type { AsyncSelectProps } from './AsyncSelect.types'
import { AsyncSelectCreateOption } from './subs/AsyncSelectCreateOption'
import { AsyncSelectSearchInput } from './subs/AsyncSelectSearchInput'
import { useAsyncSelectValuesUncontrolled } from './utils/useAsyncSelectValuesUncontrolled'

/**
 * @deprecated Use `AsyncAutocomplete` from `@libs/ui/ds` instead
 */
export const AsyncSelect = memo(
  forwardRef<HTMLInputElement, AsyncSelectProps>(
    (
      {
        className,
        /**
         * InputWrap props
         */
        label,
        desc,
        errorMessage,
        error,
        helperText,
        required,
        fillWidth,
        htmlFor,
        placeholder,
        labelRightSection,
        clearable = false,
        /**
         *
         */
        options = [],
        disabled,
        loading,
        dropdownPosition = 'bottom-start',
        emptyListLabel,
        closeOnOptionClick = true,
        onChange,
        onSearchChange,
        onCreate,
        onDebouncedSearchChange,
        debouncedSearchDelay = 500,
        dropdownHeight = 'm',
        dropdownWidth = 'm',
        defaultValue,
        searchValue,
        value,
        'data-testid': dataTestId,
        ...other
      },
      forwardedRef
    ) => {
      const innerRef = useRef<HTMLInputElement>(null)
      const ref = useMergedRef(forwardedRef, innerRef)
      const store = useCombobox({
        onDropdownClose: () => store.resetSelectedOption()
      })

      const { select, search, selectedOption } =
        useAsyncSelectValuesUncontrolled({
          select: { onChange, defaultValue, value },
          asyncSearch: {
            searchValue,
            onSearchChange,
            debouncedSearchDelay,
            onDebouncedSearchChange
          },
          options
        })

      return (
        <Combobox
          {...other}
          closeOnOptionClick={closeOnOptionClick}
          store={store}
          loading={loading}
          dropdownPosition={dropdownPosition}
          dropdownHeight={dropdownHeight}
          dropdownWidth={dropdownWidth}
          onOpen={() => innerRef?.current?.focus()}
          onOptionSubmit={val => {
            if (!val) {
              return
            }
            const newOptionSelected = options.find(i => i.value === val)
            if (newOptionSelected) {
              select.setSelectValue(val)
              search.setSearchValue(newOptionSelected.label)
              if (closeOnOptionClick) {
                store.closeDropdown()
              }
            }
          }}
        >
          <Combobox.Target>
            <AsyncSelectSearchInput
              inputRef={ref}
              data-testid={dataTestId}
              /**
               * InputWrap props
               */
              label={label}
              desc={desc}
              error={error}
              labelRightSection={labelRightSection}
              errorMessage={errorMessage}
              helperText={helperText}
              required={required}
              fillWidth={fillWidth}
              htmlFor={htmlFor}
              className={className}
              placeholder={placeholder}
              disabled={disabled}
              clearable={clearable}
              /**
               * State props
               */
              store={store}
              setSearchValue={search.setSearchValue}
              resetSearchValue={search.resetSearchValue}
              resetSelectValue={select.resetSelectValue}
              value={search.searchValue}
              selectedOption={selectedOption}
            />
          </Combobox.Target>

          {search.searchValue && (
            <Combobox.Dropdown>
              <SelectEmpty
                enabled={!onCreate}
                options={options}
                emptyListLabel={emptyListLabel}
              />
              <AsyncSelectCreateOption
                searchValue={search.searchValue}
                onCreate={onCreate}
              />
              <SelectOptionList
                selectValue={[select.selectValue]}
                options={options}
              />
            </Combobox.Dropdown>
          )}
        </Combobox>
      )
    }
  )
)

AsyncSelect.displayName = 'AsyncSelect'
export type { AsyncSelectProps } from './AsyncSelect.types'
