import { Flex, useUncontrolled } from '@libs/ui/ds'
import { useInternalDesignSystem } from '@libs/ui/ds/lib/theme/DesignSystemProvider/InternalDesignSystemProvider'
import { cva } from 'class-variance-authority'
import type { ChangeEvent } from 'react'
import { forwardRef, memo, useCallback } from 'react'

import { InputWrap } from '../../../forms/InputWrap/base/InputWrap'
import { CountryAutocomplete } from '../../Autocomplete/specialized/CountryAutocomplete/CountryAutocomplete'
import type { CountryCode } from '../../Autocomplete/specialized/CountryAutocomplete/utils/utils'
import { getCountryCallingCode } from '../../Autocomplete/specialized/CountryAutocomplete/utils/utils'
import { InputBase } from '../../InputBase/base/InputBase'

import type { PhoneInputProps, PhoneInputValue } from './PhoneInput.types'

const basePhoneInputCVA = {
  inner: cva(['!flex-nowrap gap-0.5']),
  inputBase: cva(['grow']),
  countryAutocomplete: cva(['min-w-20'])
}
const BasePhoneInput = memo(
  forwardRef<HTMLInputElement, PhoneInputProps>(
    (
      {
        label,
        desc,
        errorMessage,
        helperText,
        required,
        disabled = false,
        fillWidth,
        className,
        name,
        labelRightSection,
        placeholder,
        maxLength,
        value,
        defaultValue,
        onChange,
        ...other
      },
      ref
    ) => {
      const { config } = useInternalDesignSystem()

      const dsConfigLanguage = config.language as CountryCode
      const [controlledValue, setControlledValue] =
        useUncontrolled<PhoneInputValue>({
          value,
          onChange,
          defaultValue: defaultValue || {
            countryCode: dsConfigLanguage,
            number: ''
          }
        })

      const handleCountryChange = useCallback(
        (countryCode: string) => {
          setControlledValue({
            ...controlledValue,
            countryCode: countryCode as CountryCode
          })
        },
        [controlledValue]
      )

      const handleNumberChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
          setControlledValue({ ...controlledValue, number: e.target.value })
        },
        [controlledValue]
      )

      return (
        <InputWrap
          label={label}
          desc={desc}
          errorMessage={errorMessage}
          helperText={helperText}
          labelRightSection={labelRightSection}
          required={required}
          disabled={disabled}
          htmlFor={name}
          fillWidth={fillWidth}
          className={className}
          field={
            <Flex row className={basePhoneInputCVA.inner()}>
              <CountryAutocomplete
                disabled={disabled}
                data-testid='phone-input-country-autocomplete'
                className={basePhoneInputCVA.countryAutocomplete()}
                condensedTarget
                phoneCountryCode
                value={controlledValue?.countryCode}
                onChange={handleCountryChange}
              />
              <InputBase
                ref={ref}
                type='text'
                className={basePhoneInputCVA.inputBase()}
                aria-label={label as string}
                aria-placeholder={placeholder}
                placeholder={placeholder}
                error={Boolean(errorMessage)}
                disabled={disabled}
                name={name}
                maxLength={maxLength}
                aria-required={required}
                required={required}
                leftSection={getCountryCallingCode(
                  controlledValue?.countryCode as CountryCode
                )}
                value={controlledValue?.number}
                onChange={handleNumberChange}
                {...other}
              />
            </Flex>
          }
        />
      )
    }
  )
)

const TypedPhoneInput = BasePhoneInput
TypedPhoneInput.displayName = 'PhoneInput'

export type { PhoneInputProps } from './PhoneInput.types'
export const PhoneInput = TypedPhoneInput
export * from '../utils/utils'
