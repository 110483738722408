import { ArrowDownIcon } from '@libs/ui/ds/assets/icons'
import type { BaseComponent } from '@libs/ui/ds/lib/internals'
import type { ComboboxStore } from '@mantine/core'
import { cva } from 'class-variance-authority'
import { forwardRef } from 'react'

import { IconWrap } from '../../../../dataDisplay/IconWrap/base/IconWrap'
import { Combobox } from '../../../../overlays/Combobox/base/Combobox'
import { SearchInput } from '../../../SearchInput/base/SearchInput'
import type { SearchInputProps } from '../../../SearchInput/base/SearchInput.types'
import type { SelectOption, SelectProps } from '../Select.types'

const selectSearchInputCVA = {
  root: cva([], {
    variants: {
      disabled: {
        false: 'cursor-pointer',
        true: 'pointer-events-none'
      }
    }
  }),
  input: cva([], {
    variants: {
      searchable: {
        false: 'cursor-pointer'
      }
    }
  })
}

type SelectSearchInputProps = BaseComponent &
  SearchInputProps & {
    store: ComboboxStore
    disabled: SelectProps['disabled']
    searchable?: SelectProps['searchable']
    selectedOption?: SelectOption
    setSearchValue: (str: string) => void
    resetSearchValue: () => void
    resetSelectValue: (value: any) => void
  }

export const SelectSearchInput = forwardRef<
  HTMLInputElement,
  SelectSearchInputProps
>(
  (
    {
      as,
      label,
      desc,
      error,
      errorMessage,
      helperText,
      required,
      disabled,
      leftSection,
      fillWidth,
      htmlFor,
      clearable,
      className,
      classNames,
      store,
      placeholder,
      labelRightSection,
      setSearchValue,
      resetSearchValue,
      resetSelectValue,
      inputRef,
      value,
      selectedOption,
      searchable = false,
      'data-testid': dataTestId
    },
    ref
  ) => {
    const isClearable = Boolean(clearable && searchable && value)

    return (
      <Combobox.EventsTarget>
        <div
          className={selectSearchInputCVA.root({ disabled, className })}
          onClick={() => {
            store.openDropdown()
          }}
        >
          <SearchInput
            ref={ref}
            inputRef={inputRef}
            as={as}
            label={label}
            desc={desc}
            errorMessage={errorMessage}
            helperText={helperText}
            required={required}
            disabled={disabled}
            fillWidth={fillWidth}
            htmlFor={htmlFor}
            data-testid={dataTestId}
            clearable={isClearable}
            readOnly={!searchable}
            labelRightSection={labelRightSection}
            error={Boolean(errorMessage) || error}
            value={value}
            placeholder={placeholder}
            leftSection={leftSection}
            /**
             * event handlers
             */
            onChange={event => {
              store.updateSelectedOptionIndex()
              if (!store.dropdownOpened) {
                store.openDropdown()
              }

              if (searchable) {
                setSearchValue(event.target.value)
              }
            }}
            onFocus={() => {
              if (!store.dropdownOpened) {
                store.openDropdown()
              }
              if (searchable) {
                resetSearchValue()
              }
            }}
            onBlur={() => {
              store.closeDropdown()
              if (searchable) {
                setSearchValue(selectedOption?.label || '')
              }
            }}
            onClear={e => {
              e.stopPropagation()
              resetSelectValue([])
            }}
            /**
             * styles
             */
            classNames={{
              ...classNames,
              input: selectSearchInputCVA.input({
                searchable,
                className: classNames?.input
              })
            }}
            defaultLeftIcon={searchable}
            leftIcon={
              selectedOption?.leftIcon ? (
                <IconWrap size='s' icon={selectedOption.leftIcon} />
              ) : null
            }
            rightIcon={<ArrowDownIcon />}
          />
        </div>
      </Combobox.EventsTarget>
    )
  }
)
