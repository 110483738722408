import { SearchIcon } from '@libs/ui/ds/assets/icons'
import { useDSTranslation } from '@libs/ui/ds/lib/internals'
import { forwardRef, memo } from 'react'

import { InputWrap } from '../../../forms/InputWrap/base/InputWrap'
import { Center } from '../../../primitiveLayouts/Center/base/Center'
import { InputBase } from '../../InputBase/base/InputBase'

import type { SearchInputProps } from './SearchInput.types'
import { SearchInputExpandWrap } from './compound/SearchInputExpandWrap/SearchInputExpandWrap'
import { SearchInputClearButton } from './subs/SearchInputClearButton'

const BaseSearchInput = memo(
  forwardRef<HTMLInputElement, SearchInputProps>(
    (
      {
        label,
        desc,
        errorMessage,
        helperText,
        required,
        disabled = false,
        fillWidth,
        className,
        name,
        classNames,
        placeholder,
        rightIcon,
        defaultLeftIcon = true,
        leftIcon,
        rightSection,
        clearable = true,
        onChange,
        value,
        leftSection,
        onClear,
        debounceDelay = 'medium',
        onDebouncedChange,
        inputRef,
        labelRightSection,
        ...other
      },
      ref
    ) => {
      const { t } = useDSTranslation()

      const displayClear = Boolean(value && clearable)

      return (
        <InputWrap
          label={label}
          desc={desc}
          errorMessage={errorMessage}
          helperText={helperText}
          required={required}
          disabled={disabled}
          htmlFor={name}
          fillWidth={fillWidth}
          className={className}
          ref={ref}
          labelRightSection={labelRightSection}
          field={
            <InputBase
              classNames={classNames}
              type='search'
              value={value}
              inputRef={inputRef}
              onChange={onChange}
              debounceDelay={debounceDelay}
              onDebouncedChange={onDebouncedChange}
              disabled={disabled}
              error={Boolean(errorMessage)}
              name={name}
              aria-label={label as string}
              placeholder={
                placeholder || t('ds.components.searchInput.placeholder')
              }
              aria-required={required}
              required={required}
              leftSection={leftSection}
              rightIcon={!displayClear && rightIcon}
              rightSection={
                <Center className='flex-row gap-1'>
                  {rightSection}
                  <SearchInputClearButton
                    displayClear={displayClear}
                    setControlledValue={onChange}
                    onClear={onClear}
                  />
                </Center>
              }
              leftIcon={defaultLeftIcon ? <SearchIcon /> : leftIcon}
              {...other}
            />
          }
        />
      )
    }
  )
)

type CompoundSearchInputType = {
  ExpandWrap: typeof SearchInputExpandWrap
}

const TypedSearchInput = BaseSearchInput as typeof BaseSearchInput &
  CompoundSearchInputType

TypedSearchInput.displayName = 'SearchInput'
TypedSearchInput.ExpandWrap = SearchInputExpandWrap

export type { SearchInputProps } from './SearchInput.types'
export type { SearchInputExpandWrapProps } from './compound/SearchInputExpandWrap/SearchInputExpandWrap.types'
export const SearchInput = TypedSearchInput
