import { cva } from 'class-variance-authority'

export const inputBaseCVA = {
  root: cva(['flex rounded-md border-1 border-solid text-s text-neutral-600'], {
    variants: {
      size: {
        none: 'px-3',
        s: 'h-10 min-h-10 px-3',
        m: 'h-10 min-h-10 px-3'
      },
      disabled: {
        true: 'bg-neutral-100',
        false: 'bg-neutral-50'
      },
      error: {
        false: 'border-neutral-200 focus-within:border-brand-green-600',
        true: 'border-semantic-red-400'
      }
    }
  }),
  wrap: cva(['w-full flex-nowrap items-center'], {
    variants: {
      size: {
        none: 'gap-2',
        s: 'gap-2',
        m: 'gap-2'
      }
    }
  }),
  inner: cva(['flex min-w-0 grow flex-row flex-nowrap items-center'], {
    variants: {
      size: {
        none: 'gap-2',
        s: 'gap-2',
        m: 'gap-2'
      }
    }
  }),
  input: cva(
    [
      'cancel-search h-8 min-w-0 grow cursor-text border-none bg-inherit outline-none'
    ],
    {
      variants: {
        type: {
          date: 'read-only:!cursor-default'
        },
        disabled: {
          true: 'text-neutral-400',
          false: 'text-neutral-700'
        }
      }
    }
  )
}
