import { useMergedRef } from '@libs/ui/ds'
import type { ElementType } from 'react'
import { forwardRef, memo, useMemo } from 'react'
import * as React from 'react'

import { IconWrap } from '../../../dataDisplay/IconWrap/base/IconWrap'
import { Flex } from '../../../primitiveLayouts/Flex/base/Flex'

import { inputBaseCVA } from './InputBase.classes'
import type { InputBaseProps } from './InputBase.types'
import { useDebouncedControlledState } from './utils/useDebouncedControlledState'

const inputBasePropsConfig = {
  debounceDelay: {
    short: 100,
    medium: 300,
    long: 500,
    longest: 800
  }
}

export const InputBase = memo(
  forwardRef<HTMLInputElement, InputBaseProps>(
    (
      {
        value,
        onChange,
        onDebouncedChange,
        debounceDelay = 'medium',
        name,
        id,
        size = 'm',
        disabled = false,
        required = false,
        error = false,
        leftIcon,
        leftSection,
        rightIcon,
        rightSection,
        suffix,
        readOnly = false,
        as = 'input',
        inputRef,
        /**
         * Extended props
         */
        style,
        type,
        className,
        classNames,
        ...other
      },
      ref
    ) => {
      const innerInputRef = React.useRef<HTMLInputElement>(null)
      const mergedInputRef = useMergedRef(inputRef, innerInputRef)
      const InputComponent = useMemo(() => as as ElementType, [])

      const [controlledValue, setControlledValue] = useDebouncedControlledState(
        {
          onChange,
          value,
          onDebouncedChange,
          debounceDelay: inputBasePropsConfig.debounceDelay[debounceDelay]
        }
      )

      return (
        <div
          style={style}
          ref={ref}
          onClick={() => innerInputRef.current?.focus()}
          className={inputBaseCVA.root({
            className: [className, classNames?.root].filter(Boolean).join(' '),
            disabled,
            error,
            size
          })}
        >
          <Flex
            row
            className={inputBaseCVA.wrap({ size, className: classNames?.wrap })}
          >
            {leftIcon && <IconWrap size='s' icon={leftIcon} />}
            <Flex
              row
              className={inputBaseCVA.inner({
                size,
                className: classNames?.inner
              })}
            >
              {Boolean(leftSection) && (
                <Flex row className='items-center'>
                  {leftSection}
                </Flex>
              )}
              <InputComponent
                {...other}
                ref={mergedInputRef}
                id={id || name}
                disabled={disabled}
                name={name}
                readOnly={readOnly}
                required={required}
                type={type}
                className={inputBaseCVA.input({
                  type: type as any,
                  disabled,
                  className: classNames?.input
                })}
                onChange={setControlledValue}
                value={controlledValue}
              />
              {suffix && <div>{suffix}</div>}
            </Flex>
            {rightIcon && <IconWrap size='s' icon={rightIcon} />}
            {Boolean(rightSection) && (
              <Flex row className='items-center'>
                {rightSection}
              </Flex>
            )}
          </Flex>
        </div>
      )
    }
  )
)
InputBase.displayName = 'InputBase'
export type { InputBaseProps } from './InputBase.types'
