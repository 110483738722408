import { yupResolver } from '@hookform/resolvers/yup'
import { authService } from '@libs/data-access/queries'
import { Anchor, Flex, Button, AlertErrorAdapter, Alert } from '@libs/ui/ds'
import { BaseForm, Controller } from '@libs/ui/form'
import { TransApp, useOnboardingTranslation } from '@libs/utils/translations'
import { routes } from '@spa/core/router'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { useAtomEmailLogIn } from '../shared/atoms/atomEmailLogIn'

import { useForgottenPasswordFormValidation } from './useForgottenPasswordForm.validation'

type ForgottenPasswordFormField = {
  email: string
}

export const ForgottenPasswordForm = () => {
  const lastEmailSent = useRef<string | null>(null)
  const [emailFromLogin, setEmail] = useAtomEmailLogIn()
  const { t, tCommon } = useOnboardingTranslation()
  const { mutateAsync, isSuccess, error } =
    authService.usePostResetUserPassword()
  const forgottenPasswordFormValidation = useForgottenPasswordFormValidation()

  const methods = useForm<ForgottenPasswordFormField>({
    defaultValues: {
      email: emailFromLogin
    },
    resolver: yupResolver(forgottenPasswordFormValidation)
  })

  const { isSubmitting } = methods.formState

  const onSubmit = async (data: ForgottenPasswordFormField) => {
    await mutateAsync(
      { payload: data },
      {
        onSuccess: () => {
          lastEmailSent.current = data.email
          methods.reset()
        }
      }
    )
  }

  return (
    <BaseForm methods={methods} onSubmit={onSubmit}>
      <Flex className='gap-4'>
        {isSuccess && (
          <Alert
            intent='semantic.blue'
            data-testid='reset-password-success-alert'
          >
            <TransApp
              t={t}
              i18nKey='onboarding.password.forgotten.emailSent'
              values={{ email: lastEmailSent.current }}
            />
          </Alert>
        )}
        <AlertErrorAdapter error={error?.error} />
        <Controller.TextInput
          data-testid='forgotten-password-email-input'
          name='email'
          control={methods.control}
          label={tCommon('userInfo.email')}
          type='email'
        />
        <Button
          loading={isSubmitting}
          type='submit'
          data-testid='forgotten-password-submit-button'
        >
          {t('onboarding.password.forgotten.resetButton')}
        </Button>
        <Anchor
          onClick={() => setEmail('')}
          component={Link}
          to={routes.signIn.rootPath}
          variant='primary'
          className='text-center text-s'
        >
          {t('onboarding.password.forgotten.backLogIn')}
        </Anchor>
      </Flex>
    </BaseForm>
  )
}
