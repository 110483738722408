/* eslint-disable import/no-named-as-default */

import { SuspenseWrap } from '@libs/ui/ds'
import { routes } from '@spa/core/router'
import ForgottenPassword from '@spa/scenes/Auth/ForgottenPassword/ForgottenPassword'
import SignIn from '@spa/scenes/Auth/SignIn/SignIn'
import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

import { AuthCardLayout } from './AuthCardLayout'
import { GuestAppRedirects } from './GuestAppRedirects'
import { SignupSplitLayout } from './SignupSplitLayout'

const SignUpFlow = lazy(() => import('@spa/scenes/Auth/SignUpFlow/SignUpFlow'))
const PendingInvitation = lazy(
  () => import('@spa/scenes/Auth/PendingInvitation/PendingInvitation')
)
const NotSentInvitation = lazy(
  () => import('@spa/scenes/Auth/NotSentInvitation/NotSentInvitation')
)

export const GuestAppRoutes = React.memo(() => {
  return (
    <SuspenseWrap>
      <Routes>
        <Route element={<AuthCardLayout />}>
          <Route path={routes.signIn.path} element={<SignIn />} />
          <Route
            path={routes.forgottenPassword.path}
            element={<ForgottenPassword />}
          />
          <Route path={routes.editPassword.path} element='EditPassword' />
          <Route path={routes.signUpInvitation.path} element='UserInvitation' />
          <Route
            path={routes.pendingInvitation.path}
            element={<PendingInvitation />}
          />
          <Route
            path={routes.notSentInvitation.path}
            element={<NotSentInvitation />}
          />
        </Route>
        <Route element={<SignupSplitLayout />}>
          <Route path={routes.signUp.path} element={<SignUpFlow />} />
          <Route path={routes.signUpCompany.path} element='Signup Company' />
        </Route>

        <Route path={routes.signOut.path} element='SignOut' />

        <Route path='*' element={<GuestAppRedirects />} />
      </Routes>
    </SuspenseWrap>
  )
})
