/* eslint-disable import/no-extraneous-dependencies */
import i18nCountries from 'i18n-iso-countries'
import enLocale from 'i18n-iso-countries/langs/en.json'
import esLocale from 'i18n-iso-countries/langs/es.json'
import frLocale from 'i18n-iso-countries/langs/fr.json'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import type { ReactNode } from 'react'
import { initReactI18next, I18nextProvider } from 'react-i18next'

import { localResourcesToBackend } from './utils/localResourcesToBackend'

export const i18nDSInstance = i18next.createInstance()

/**
 * Register i18n-iso-countries locales
 */
i18nCountries.registerLocale(frLocale)
i18nCountries.registerLocale(enLocale)
i18nCountries.registerLocale(esLocale)

/**
 * Initialize i18n for ds,
 * Fallback language, EN and FR
 * Default namespace that will be loaded initially
 */
export const initDSI18n = async () => {
  i18nDSInstance
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(localResourcesToBackend())
    .init({
      fallbackLng: ['en'],
      defaultNS: 'ds',
      keySeparator: '.',

      detection: {
        order: ['localStorage', 'navigator'],
        caches: ['localStorage']
      },

      interpolation: {
        escapeValue: false
      },

      react: {
        useSuspense: true
      }
    })
}

initDSI18n()

export const I18nDSProvider = ({ children }: { children: ReactNode }) => {
  return <I18nextProvider i18n={i18nDSInstance}>{children}</I18nextProvider>
}
