import i18nCountries from 'i18n-iso-countries'
import type { CountryCode } from 'libphonenumber-js'
import * as LibPhoneJs from 'libphonenumber-js'

const SUGGESTED_COUNTRIES_CODE = ['FR', 'ES']

type CountryDerivedData = {
  code: string
  name: string
  label: string
  flagUrl: string
}

/**
 * Returns the URL of the flag for a given country code
 */
export const getCountryFlag = (code: string) => {
  return `https://flagcdn.com/24x18/${code.toLowerCase()}.webp`
}

/**
 * Filters the available countries based on the current locale
 */
const filterAvailableCountries = (
  countries: string[],
  currentLocale: string
) => {
  return countries.filter(code => i18nCountries.getName(code, currentLocale))
}

const getCountries = (currentLocale: string) => {
  return filterAvailableCountries(LibPhoneJs.getCountries(), currentLocale)
}

const getCountryName = (code: string, currentLocale: string) => {
  return i18nCountries.getName(code, currentLocale) || code
}

const sortCountries = (countriesDerivedData: CountryDerivedData[]) => {
  return countriesDerivedData.sort((a, b) => {
    const aIsSuggested = SUGGESTED_COUNTRIES_CODE.includes(a.code)
    const bIsSuggested = SUGGESTED_COUNTRIES_CODE.includes(b.code)

    if (aIsSuggested !== bIsSuggested) {
      return aIsSuggested ? -1 : 1
    }

    return a.name.localeCompare(b.name)
  })
}

/**
 * Returns the country calling code for a given country code
 */
export const getCountryCallingCode = (code: CountryCode) => {
  return `+${LibPhoneJs.getCountryCallingCode(code?.toUpperCase() as CountryCode)}`
}

/**
 * Returns the country calling code formatted for display
 */
export const getCountryCallingCodeFormatted = (code: CountryCode) => {
  return `(${getCountryCallingCode(code)})`
}

/**
 * Returns the label for a given country code
 */
const getLabel = (opts: {
  code: string
  currentLocale: string
  withPhoneCountryCode?: boolean
}) => {
  const countryName =
    i18nCountries.getName(opts.code, opts.currentLocale) || opts.code

  if (opts.withPhoneCountryCode) {
    return `${countryName} ${getCountryCallingCodeFormatted(
      opts.code as CountryCode
    )}`
  }

  return countryName
}

/**
 * Returns the derived countries data for the current locale
 */
export const getDerivedCountriesData = (
  currentLocale: string,
  withPhoneCountryCode?: boolean
): CountryDerivedData[] => {
  const countries = getCountries(currentLocale)
  const countriesDerivedData = countries.map(i => ({
    code: i,
    name: getCountryName(i, currentLocale),
    label: getLabel({ code: i, currentLocale, withPhoneCountryCode }),
    flagUrl: getCountryFlag(i)
  }))

  return sortCountries(countriesDerivedData)
}

export type { CountryCode }
