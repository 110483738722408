import { useDSTranslation } from '@libs/ui/ds'
import type { FC, ForwardedRef } from 'react'

import { Button } from '../../../../../buttons/Button/base/Button'

import type { AutocompleteTargetButtonProps } from './AutocompleteTargetButton.types'

export const AutocompleteTargetButton: FC<AutocompleteTargetButtonProps> = ({
  selectedOptions,
  store,
  disabled,
  leftIcon,
  rightIcon,
  ref,
  ...otherProps
}) => {
  const { t } = useDSTranslation()
  const numberOfSelectedOptions = selectedOptions.length

  return (
    <Button
      {...otherProps}
      data-testid={otherProps['data-testid'] || 'custom-target-button'}
      ref={ref as ForwardedRef<HTMLButtonElement>}
      onClick={() => {
        store.toggleDropdown()
      }}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      intent='neutral-dark'
      variant='secondary'
      disabled={disabled}
    >
      {t('ds.components.autocomplete.simpleButton.label', {
        count: numberOfSelectedOptions
      })}
    </Button>
  )
}
