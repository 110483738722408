import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import React from 'react'

import { Flex } from '../../../../primitiveLayouts/Flex/base/Flex'
import type { AutocompleteTargetInputLikeProps } from '../../base/Autocomplete'
import { Autocomplete } from '../../base/Autocomplete'

const countryAutocompleteCVA = {
  root: cva(['items-center gap-4']),
  flagImage: cva(['w-4'])
}

export const CountryAutocompleteTarget: FC<
  AutocompleteTargetInputLikeProps & { condensedTarget?: boolean }
> = ({ selectedOptions, condensedTarget = false, ...props }) => {
  const [firstSelectedOption] = selectedOptions

  return (
    <Autocomplete.Targets.InputLike
      {...props}
      selectedOptions={selectedOptions}
    >
      <Flex row className={countryAutocompleteCVA.root()}>
        <div>{firstSelectedOption?.leftSection}</div>
        {!condensedTarget && <div>{firstSelectedOption?.label}</div>}
      </Flex>
    </Autocomplete.Targets.InputLike>
  )
}
